import { forwardRef, useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// mui
import { Autocomplete, Box, LinearProgress } from '@mui/material';

// react-infinite-scroll-hook
import useInfiniteScroll from 'react-infinite-scroll-hook';
// use-debounce hook
import { useDebounce } from 'use-debounce';
// autosuggest-highlight
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import handleError from 'utils/handle-error';
import callAzureFunctionPublic from 'utils/call-azure-function-public';
import callAzureFunction from 'utils/call-azure-function';

// ==============================|| CUSTOM AUTOCOMPLETE WITH INFINITE SCROLL ||============================== //

/**
 * Custom `Autocomplete` with integrated text search and infinite scroll for its list of options.
 * @prop `optionsUrl` - `required` The URL path pointing to the function that will return the paginated options.
 * @prop `optionsUrlIsPublic` Determines if the function to be called is public or not. Defaults to `false`.
 * @prop `optionsUrlParams` Additional parameters to be passed to the url.
 * @prop `optionsPageSize` The number of options to return per page. Defaults to `25`.
 * @prop `optionsFirstPageNumber` The number of the first page. Generally 0 or 1. Defaults to `0`.
 * @prop `optionsHighlight` If `true`, the search string will be highlighted in each option. Defaults to `false`.
 * @prop `optionsHighlightColor` The color to be used for highlighting. Defaults to `default` text color.
 * @prop `minSearchInput` The minimum number of characters required to execute text search. Defaults to `3`.
 */
const AutocompleteWithInfiniteScroll = forwardRef(
  (
    {
      optionsUrl,
      optionsUrlIsPublic = false,
      optionsUrlParams = {},
      optionsPageSize = 25,
      optionsFirstPageNumber = 0,
      optionsHighlight = false,
      optionsHighlightColor = '',
      minSearchInput = 0,
      sx = {},
      ...others
    },
    ref
  ) => {
    const { limitTags, ListboxProps } = others;

    const [open, setOpen] = useState(false);
    // options
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(optionsFirstPageNumber);
    // const [page, setPage] = useState(1);
    // input value
    const [inputValue, setInputValue] = useState('');
    // search (only set when length of input value >= minSearchInput)
    const [search, setSearch] = useState('');
    // debounced search value
    const [searchValue] = useDebounce(search, 800);

    const getOptions = useCallback(
      async (params = {}) => {
        let response;

        if (optionsUrlIsPublic) {
          // use public endpoint
          response = await callAzureFunctionPublic({
            url: optionsUrl,
            method: 'get',
            params
          });
        } else {
          // use private endpoint (default)
          response = await callAzureFunction({
            url: optionsUrl,
            method: 'get',
            params
          });
        }

        return response.data;
      },
      [optionsUrl, optionsUrlIsPublic]
    );

    // Use useRef to maintain a stable reference for optionsUrlParams otherwise it loops
    const optionsUrlParamsRef = useRef(optionsUrlParams);
    optionsUrlParamsRef.current = optionsUrlParams;

    const getInitialOptions = useCallback(async () => {
      try {
        setLoading(true);

        const params = {
          ...optionsUrlParamsRef.current,
          // when getting an initial set of options, always start from first page
          page: optionsFirstPageNumber,
          pageSize: optionsPageSize,
          search: searchValue
        };

        const { rows, hasNextPage } = await getOptions(params);

        setOptions(rows);
        setHasNextPage(hasNextPage);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }, [getOptions, optionsFirstPageNumber, optionsPageSize, searchValue]);

    useEffect(() => {
      // when the search value has enough characters, get the initial options
      if (open === true && searchValue.length >= minSearchInput) {
        getInitialOptions();
      }
    }, [getInitialOptions, minSearchInput, open, searchValue]);

    const loadMoreOptions = async () => {
      try {
        setLoading(true);

        const newPage = page + 1;
        const params = {
          ...optionsUrlParams,
          // when loading more options, use the next page
          page: newPage,
          pageSize: optionsPageSize,
          search: searchValue
        };

        const { rows, hasNextPage } = await getOptions(params);

        // append new options
        setOptions((prev) => [...prev, ...rows]);
        setHasNextPage(hasNextPage);
        // update current page
        setPage(newPage);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    const [sentryRef, { rootRef: infiniteScrollRef }] = useInfiniteScroll({
      loading,
      hasNextPage,
      disabled: !open,
      onLoadMore: () => loadMoreOptions()
    });

    const handleInputChange = (e, newInputValue) => {
      if (e?.type === 'change' && newInputValue?.length >= minSearchInput) {
        // update search string
        setSearch(newInputValue);
      } else {
        // not enough characters to trigger search
        setSearch('');
        setOptions([]);
      }

      if (others.freeSolo && others.multiple) {
        if (newInputValue.endsWith(',')) {
          e.target.blur();
          e.target.focus();
          return;
        }

        if (newInputValue.endsWith(' ')) {
          e.target.blur();
          e.target.focus();
          return;
        }
      }

      setPage(0);
      setHasNextPage(false);
      setInputValue(newInputValue);
    };

    const renderSentry = () =>
      hasNextPage || loading ? (
        <li ref={sentryRef} key="sentry">
          <LinearProgress />
        </li>
      ) : null;

    const handleNoOptionsText = () => {
      if (search.length < minSearchInput) {
        return `Input at least ${minSearchInput} characters to execute search.`;
      }

      return 'No options found. Enter a search text to try again.';
    };

    const handleRenderOption = (props, option, state, ownerState) => {
      const { id, label } = option;

      if (id === 'sentry') {
        return renderSentry();
      }

      // use 'getOptionLabel' function, else default to 'label'
      const newLabel = ownerState?.getOptionLabel ? ownerState.getOptionLabel(option) : label;

      let parts = [];
      if (optionsHighlight && id !== 'sentry') {
        const matches = match(newLabel, search, { insideWords: true, findAllOccurrences: true });
        parts = parse(newLabel, matches);
      }

      return (
        <li {...props} key={id}>
          {optionsHighlight ? (
            <>
              {/* render parts with highlight */}
              {parts.length > 0 ? (
                <Box>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{
                        fontWeight: part.highlight ? 'bold' : 'regular',
                        color: part.highlight && optionsHighlightColor ? optionsHighlightColor : 'unset'
                      }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Box>
              ) : (
                // render label if there are no parts
                <>{newLabel}</>
              )}
            </>
          ) : (
            // render label if highlighting is not enabled
            <>{newLabel}</>
          )}
        </li>
      );
    };

    return (
      <Autocomplete
        {...others}
        ref={ref}
        sx={{
          ...sx
          // add custom styles here
        }}
        // by default tags are limited to 5 unless a different limit is provided
        limitTags={typeof limitTags === 'number' ? limitTags : 5}
        slotProps={{
          listbox: {
            // apply provided Listbox props if any
            ...(ListboxProps || {}),
            style: {
              // set default Listbox max height
              maxHeight: 300,
              overflow: 'auto',
              // set provided Listbox style props if any
              ...(ListboxProps?.style || {})
            },
            // apply ref from useInfiniteScroll hook to Listbox
            ref: infiniteScrollRef
          }
        }}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // options
        options={options}
        noOptionsText={handleNoOptionsText()}
        filterOptions={(options) => {
          if (hasNextPage) {
            options.push({ id: 'sentry', label: '' });
          }

          return options;
        }}
        renderOption={handleRenderOption}
        // input value
        inputValue={inputValue}
        onInputChange={handleInputChange}
      />
    );
  }
);

AutocompleteWithInfiniteScroll.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  optionsUrlIsPublic: PropTypes.bool,
  optionsUrlParams: PropTypes.object,
  optionsPageSize: PropTypes.number,
  optionsFirstPageNumber: PropTypes.number,
  optionsHighlight: PropTypes.bool,
  optionsHighlightColor: PropTypes.string,
  minSearchInput: PropTypes.number,
  sx: PropTypes.object
};

export default AutocompleteWithInfiniteScroll;
